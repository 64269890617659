    
  .navigation-list {
    float: left;
  }
  
  .navigation-list a {
    display: block;
    color: #2b0060;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
  }
  
  .navigation-list a:hover:not(.active) {
    background-color: #111;
  }
  
  .active {
    background-color: #04AA6D;
  }