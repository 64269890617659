input[type=date] {
    width: auto;
    padding: 12px 10px;
    margin: 8px 0;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
  }

button {
    width: auto;
    background-color: #4CAF50;
    color: white;
    padding: 6px 10px;
    margin: 1px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }

  input[type=submit] {
    width: 100%;
    background-color: #4CAF50;
    color: white;
    padding: 14px 20px;
    margin: 8px 0;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  input[type=submit]:hover {
    background-color: #45a049;
  }
  input[type=checkbox] {
    width: 100%;
    background-color: #4CAF50;
    color: white;
    padding: 14px 0px;
    margin: 2px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }

  input[type=numeric], input[type=number] {
    width: auto;
    padding: 14px 20px;
    margin: 2px 15px;
    border: 1px solid #ccc;
    border-radius: 4px;
    cursor: pointer;
  }
