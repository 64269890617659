.navigation {
    list-style-type: none;
    top:0px;
    margin: 0;
    padding: 8px;
    overflow: hidden;
    background-color: #f2f2f2;
    /* position: -webkit-sticky; */
    position: sticky
    
  }
  
  .navigation-list {
    float: left;
  }
  
  .navigation-list a {
    display: block;
    color: #2b0060;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
  }
  
  .navigation-list a:hover:not(.active) {
    background-color: #dfc7c7;
  }
  
  .active {
    background-color: #04AA6D;
  }