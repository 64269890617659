table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
  }
  
  td, th {
    border: 1px solid #b39393;
    text-align: left;
    padding: 8px;
  }
  
  tr:nth-child(even) {
    background-color: #c3f0ba;
  }